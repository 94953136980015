.version-number-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    border: 0.5px solid #777;
    color: #444;
    padding: 0.05rem 0.313rem 0;
    background-color: #e2e2e2;
    border-radius: 5px;
    margin: 0 0 0.3rem 0.2rem;
    font-size: 0.8rem;
    font-weight: 600;
}