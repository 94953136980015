@use '../../main';

.ui.vertical.segment.overlay.left.wide.visible.sidebar.left-sidebar-data {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: main.$leftSidebarWidth;
    max-height: main.$sidebarHeights;
    border-radius: 0 10px 10px 0 !important;
    box-shadow: main.$sidebarBoxShadow;
    background-color: main.$primary-navbar;
    margin-top: main.$sidebarMarginTop !important;

    .left-sidebar-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: calc(100vh - 112px);

        .left-sidebar-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 1.25rem;
            background: linear-gradient(180deg, #9AC4E6 30%, #056B99 80%);

            .left-header-frame {
                color: #262626;
                font-family: "Roboto", sans-serif;
                font-size: 2rem;
                font-weight: 800;
                text-shadow: main.$headerTextShadow;
                letter-spacing: -1px;
                line-height: 2rem;
                padding: 0.25rem 0 1.1rem;
                margin: 0;
                background: white;
                border: 3px solid main.$primary-bright-blue;
                border-radius: 10px;
                height: 100%;

                .left-header-icon {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    i.icon {
                        height: fit-content;
                        width: fit-content;
                        font-size: 1.5rem;
                        padding: 0.1rem 0 0 0.7rem;
                        text-shadow: none;
                        -webkit-text-stroke-width: 1px;
                    }

                    i.icon:hover {
                        cursor: pointer;
                    }
                }

                .left-header-text {
                    margin: -1.4rem 2.5rem 0;
                }
            }
        }

        // hides scrollbar
        .left-body::-webkit-scrollbar {
            appearance: none;
            width: 0;
        }

        .left-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 1.1rem 2.5rem;
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            text-align: justify;
            background-color: main.$primary-navbar;
            width: 100%;
            color: white;
            overflow-y: auto;
        }

        .left-sidebar-title {
            font-size: 1.4rem;
            text-align: center;
            margin: 0.624rem 0;
            font-weight: 600;
        }

        .ui.divider {
            margin: 0;
            width: 90%;
            height: 0;
            border-bottom: 0.5px solid white;
        }

        .selected-site-info {
            width: 100%;
            text-align: left;
            margin: 0.625rem 0 1.25rem;
            font-size: 1rem;

            .site-info {
                display: flex;
                flex-direction: row;
                margin: 0.313rem 0;

                .site-info-key {
                    font-weight: 600;
                    width: 11rem;
                    margin-right: 0.313rem;
                    text-align: right;
                    white-space: nowrap;
                }

                .site-info-value {
                    margin-left: 0.313rem;
                }
            }
        }

        .sidebar-site-buttons {
            .button-component {
                .ui.button {
                    min-width: 180px;
                    margin: 5px;
                }
            }
        }

        .sidebar-image-wrapper {
            width: 16rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-top: 2rem;

            .site-preview-header {
                text-align: center;
                margin-bottom: 0.1rem;
                font-weight: 500;
            }

            img {
                width: 100%;
                border: 2px solid main.$buttonBorderPrimary;
                border-radius: 0.8rem;
            }
        }
    }
}

.ui.popup.site-preview-popup {
    box-shadow: main.$popupBoxShadow;

    img {
        border-radius: 0.3rem;
    }
}