@use "../../main";

.resource {
    padding: 0;
    cursor: default;

    .welcome-intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ebf6f7;
        padding: 50px 50px 40px;

        .welcome-header {
            font-family: main.$headerFont;
            font-size: 4rem;
            line-height: 5rem;
        }

        .welcome-body {
            font-family: 'Arimo', sans-serif;
            font-size: 1rem;
            margin: 3rem 3rem 0;
            line-height: 1.4rem;
            text-align: justify;
            max-width: 1005px;
        }

        .welcome-button {
            line-height: 1rem;
            margin-top: 30px;

            .button-component {
                button.ui.button {
                    vertical-align: top;
                    margin-right: 0;
                    color: white;
                    background-color: main.$headerBlue;
                    font-family: main.$primaryFont;
                }
            }
        }
    }

    .welcome-links-subtitle {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 30px 0 0px;
    }

    .welcome-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 30px 40px 40px;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 5px 20px 16px;
            margin: 15px 25px;
            box-shadow: main.$boxShadow;
            color: black;
            text-decoration: none;
            transition: all .1s ease-in-out;
        }

        a:hover {
            cursor: pointer;
            transform: scale(1.03);
        }

        .link {
            .link-image {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 180px;
                }
            }

            .divider {
                width: 100%;
                border-top: 1px solid lightgray;
                margin-top: 10px;
                height: 2px;
            }

            .link-text {
                max-width: 220px;

                .link-text-header {
                    font-size: 1.3rem;
                    font-weight: 700;
                    margin: 10px;
                    color: main.$primary-mustard;
                    text-shadow: main.$linkTextShadow;
                }

                .link-text-body {
                    font-size: 1rem;
                    font-weight: 400;
                    text-align: justify;
                    padding: 0 5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .resource {
        .welcome-links {
            display: flex;
            flex-direction: column;
            align-items: center;

            .link {
                max-width: 500px;

                .link-text {
                    max-width: 100%;

                    .link-text-body {
                        text-align: left;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .resource {
        .welcome-intro {
            padding: 30px 30px 30px;

            .welcome-header {
                font-size: 3rem;
                line-height: 3.5rem;
                padding: 0 20px;
            }

            .welcome-body {
                margin: 1.5rem 2rem 0;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .resource {
        .welcome-intro {
            .welcome-header {
                font-size: 2.5rem;
                line-height: 2.8rem;
            }

            .welcome-body {
                padding: 0;
                margin: 20px 0;
            }
        }

        .welcome-links {
            padding: 10px 0;
        }
    }
}