@use '../../../main';

.summary {
    padding: 2rem;
    text-align: left;

    .section-header,
    .chart-header {
        // see main.scss for global styling
        padding: 0;
        margin: 0;
    }
}