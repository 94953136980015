@use '../../main';

// hides outer scrollbar
.ui.vertical.segment.overlay.left.wide.visible.sidebar.intro-left-sidebar::-webkit-scrollbar {
    appearance: none;
    width: 0;
}

.ui.vertical.segment.overlay.left.wide.visible.sidebar.intro-left-sidebar {
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    width: main.$leftSidebarWidth;
    border-radius: 0 10px 10px 0 !important;
    z-index: 10;
    box-shadow: main.$sidebarBoxShadow;

    // hides inner scrollbar
    .intro-left-sidebar-wrapper::-webkit-scrollbar {
        appearance: none;
        width: 0;
    }

    .intro-left-sidebar-wrapper {
        padding: 0;
        margin: 0;
        width: calc(main.$leftSidebarWidth);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .intro-left-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 1.25rem;
            background: linear-gradient(180deg, #9AC4E6 30%, #056B99 80%);
            min-height: 14.5rem;

            .intro-left-header-frame {
                color: #262626;
                font-family: "Roboto", sans-serif;
                font-size: 2rem;
                font-weight: 800;
                text-shadow: main.$headerTextShadow;
                letter-spacing: -1px;
                line-height: 2rem;
                padding: 1.3rem 1.25rem 3.2rem;
                margin: 0;
                display: flex;
                background: white;
                border: 3px solid main.$primary-bright-blue;
                border-radius: 10px;
                height: 100%;
            }

            .hex-drop {
                margin-top: -2.6rem;
                width: 5rem;

                img {
                    width: 100%;
                }
            }
        }

        .intro-left-body {
            flex: 1;
            padding: 0 1.1rem 2.2rem;
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            text-align: justify;
            background-color: main.$primary-navbar;
            width: 100%;

            .intro-text {
                color: white;
                padding: 0.5rem 0.4rem 1rem;
                line-height: 1.2rem;
            }

            .intro-tabs {
                .intro-left-tabs {
                    .ui.attached.tabular.menu {
                        padding-top: 0.65rem;
                        margin-bottom: 0 !important;

                        .item {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            background-color: #e4e4e4;
                            border-radius: 8px 8px 0 0;
                            padding: 0 !important;
                            line-height: 0.5rem;
                            min-height: 0;
                            max-height: 2.2rem;
                            width: 12.825rem;
                            margin-bottom: 0 !important;
                            color: gray;
                            font-size: 1.2rem;
                            font-weight: 600;
                        }

                        .item:first-child {
                            margin-right: 2px;
                        }

                        .item:last-child {
                            width: 12.85rem;
                        }

                        .item.active {
                            font-weight: 600;
                            color: white;
                            text-shadow: main.$tabTextShadow;
                            background: #cbe6f5;
                            background: main.$gradient1;
                            border-radius: 8px 8px 0 0 !important;
                        }
                    }

                    // site tab panels scrollbar styling
                    .ui.bottom.attached.segment.active.tab::-webkit-scrollbar {
                        appearance: none;
                        width: 0.5rem;
                    }

                    .ui.bottom.attached.segment.active.tab::-webkit-scrollbar-thumb {
                        border-radius: 0.313rem;
                        background-color: #c0d6df;
                    }

                    .ui.bottom.attached.segment.active.tab::-webkit-scrollbar-track {
                        border-radius: 0.313rem;
                        background-color: #dae4e8;
                    }

                    .ui.bottom.attached.segment.active.tab {
                        padding: 0.625rem 1rem;
                        margin-top: -1px !important;
                        border-radius: 0 0 0.313rem 0.313rem;
                        max-height: calc(100vh - 30rem);
                        overflow-y: auto;

                        .label {
                            color: white;
                            font-size: 1rem;
                            font-weight: 600;
                            text-shadow: main.$tabTextShadow;
                            background-color: main.$primary-bright-blue;
                            padding: 0.3rem 0.7rem;
                            margin: 0.5rem 0;
                            border-radius: 0.313rem;
                            box-shadow: main.$boxShadow;
                            text-align: left;
                        }

                        .label:hover {
                            background-color: main.$primary-navbar;
                            box-shadow: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}