@use '../../main';

.navbar {
    background-color: main.$primary-navbar;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    min-width: 100%;
    padding: 5px 16px 5px 10px;
    box-shadow: main.$navBoxShadow;
    font-family: main.$primaryFont;
    position: sticky;
    z-index: 100;
    height: main.$navbarHeight;
    cursor: default;

    .nav-inner-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .div-left-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            margin: 5.5px 5px 6px;
            color: white;
            text-shadow: main.$darkTextShadow;
            font-size: 1.8rem;
            font-weight: bolder;
            background: hsla(199, 58%, 34%, 1);
            background: radial-gradient(circle, hsla(199, 58%, 34%, 1) 0%, hsla(198, 38%, 68%, 1) 100%);
            background: -moz-radial-gradient(circle, hsla(199, 58%, 34%, 1) 0%, hsla(198, 38%, 68%, 1) 100%);
            background: -webkit-radial-gradient(circle, hsla(199, 58%, 34%, 1) 0%, hsla(198, 38%, 68%, 1) 100%);
            border: 0.5px solid white;
            min-height: 40px;
            border-radius: 3px;

            .nav-logo {
                height: 100%;
            }
        }

        .div-right-container {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            color: main.$navbarLinksWhite;

            .nav-hamburger-menu {
                display: none;
                color: main.$navbarLinksWhite;

                i.icon {
                    color: main.$navbarLinksWhite;
                    font-size: 2rem;
                }
            }

            .nav-large-menu {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .large-menu-option {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    color: main.$navbarLinksWhite;
                    text-shadow: main.$darkTextShadow;
                    font-size: 1.5rem;
                    font-weight: bolder;
                    margin: 0 0.3rem;
                    border-radius: 3px;
                    padding: 10px 20px;
                    background-color: #056b99;

                    .logout-name {
                        cursor: pointer;
                    }

                    .menu-name {
                        margin-left: 3px;
                    }
                }

                .large-menu-option:hover {
                    cursor: pointer;
                    background-color: #ffffff17;
                    text-shadow: none;
                }
            }
        }
    }
}

// mobile/small screen styling
@media only screen and (max-width: 769px) {
    .navbar {
        .nav-inner-container {
            .div-right-container {
                .nav-hamburger-menu {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding-top: 0.7rem;

                    .hamburger-logout-name {
                        padding-bottom: 0.7rem;
                        margin-left: 20px;
                    }
                }

                .ui.dropdown.nav-hamburger-menu .menu {
                    margin: 0 0 0 -45px !important;
                }

                .hamburger-logout {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1rem;
                    font-size: 1.3rem;
                    font-weight: bold;
                    -webkit-text-stroke-width: 0.1px;
                    -webkit-text-stroke-color: main.$navbarLinksWhite;
                    text-shadow: main.$darkTextShadow;

                    .hamburger-logout-name {
                        margin-top: 3px;
                    }
                }

                .nav-large-menu {
                    display: none;
                }
            }
        }
    }
}