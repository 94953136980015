@use '../../main';

.button-group-component {
    padding: 0;
    margin: 0;

    .ui.buttons {
        .ui.button {
            flex: 1;
            font-size: 1.1rem;
            white-space: nowrap;
            padding: 7px 15px 8px;
            color: white;
            text-shadow: main.$tabTextShadow;
            background-color: main.$buttonGroupReg;
            border-right: 1.5px solid #f0f0f0;
        }

        .ui.button:last-child {
            border-right: none;
        }

        .ui.button.active,
        .ui.button:hover {
            color: white;
            background-color: main.$buttonGroupActive;
        }
    }
}