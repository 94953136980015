@use "../../main";

.data-page {
    padding: 0;
    padding-top: 1px;
    overflow: hidden;

    .ui.segment.pushable {
        border-radius: 0px !important;
        border: none !important;
        cursor: default;
        overflow: hidden !important;

        .pusher {
            border-radius: 0px !important;
            border: none !important;

            .map-wrapper {
                display: flex;
                flex-direction: row;
                align-content: stretch;
                align-items: stretch;
                justify-content: stretch;
                min-height: calc(100vh - main.$navbarHeight);

                .segment {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 !important;
                    margin: 0 !important;
                    border-radius: 0px !important;
                    border: none !important;
                    min-width: 100% !important;
                    height: calc(100vh - main.$navbarHeight); // needs to fill full height
                    position: relative;
                    text-align: center;

                    .map {
                        height: calc(100vh - main.$navbarHeight);
                        width: 100%;
                    }
                }
            }
        }

        // both sidebars
        .segment {
            overflow: hidden;
            background-color: #fff;
        }

        // right sidebar
        .ui.vertical.segment.overlay.right.very.wide.visible.sidebar {
            width: calc(100% - main.$leftSidebarWidth - 3px);
            max-height: main.$sidebarHeights;
            margin-top: main.$sidebarMarginTop !important;
            padding: 0 !important;
            justify-content: flex-start;
            border-radius: 10px 0 0 10px !important;
            box-shadow: main.$leftShadeBoxShadow;

            .right-sidebar-outer-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .x-close {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    padding: 0.5rem 0 0 0.313rem;
                    width: 100%;

                    i.icon {
                        font-size: 1.5rem;
                        color: main.$primary-blue;
                    }

                    i.icon:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.data-too-small {
    display: none;
}

@media only screen and (max-width: 710px) {
    .data-page {
        display: none;
    }

    .data-too-small {
        display: block;
        padding: 30px;
        margin: 20px;
        text-align: center;
        font-weight: bolder;
        border: 1px solid lightgray;
        border-radius: 10px;
    }
}