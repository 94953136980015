@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=Lato:wght@300;400;700;900&family=Anton:wght@400&display=swap');

$primary-blue: #004c6d;
$primary-light-blue: #90b6c6;
$primary-bright-blue: #60a7ca;
$primary-bright-light-blue: #9FDBF1;
$primary-lightest-blue: #cbe1e8;
$primary-yellow: #f3ba40;
$primary-mustard: #daa135;
$primary-orange: #eb9c09;
$primary-green: #41a442;
$primary-light-green: #91cea8;
$primary-navbar: #056b99;
$navbarLinks: #005273;
$navbarLinksWhite: #f9fafb;
$headerBlue: #267b9f;
$headerDarkGreen: #63967c;
$darkPlaceholder: #959595;
$sidebarBackground: #f8f8f8;
$buttonGroupActive: $primary-navbar;
$buttonGroupReg: #8db9d2;
$gradient1: linear-gradient(to bottom, #0776b6, #61b0dd) !important; //active tabs

// ----- Start Box Shadow Styles
$boxShadow: 1px 1px 2px 0 #a6a6a6;
$btnBoxShadow: 1px 1px 1.5px 0 #6f6f6f;
$popupBoxShadow: 3.5px 4px 5px 0 #6f6f6f;
$sidebarBoxShadow: 1px -1px 2px 0 #7e7e7e;
$leftShadeBoxShadow: -1px -1px 2px 0 #989898;
$lightBoxShadow: 1px 1px 3px 0 #ffffff;
$navBoxShadow: 0 0.5px 1px 0 #afafaf;
$tabBoxShadow: 1px 0px 1px 0px #9e9e9e;
// ----- End Box Shadow Styles

// ----- Start Text Shadow Styles
$darkTextShadow: 1px 1px 1px #000;
$headerTextShadow: 1px 1px 2px rgb(179, 179, 179);
$lightTextShadow: 0px 0.5px 1px #ffffff;
$brightWhiteTextShadow: 1px 1px 1px #ffffff;
$navTextShadow: 0 1px 2px #ececec;
$linkTextShadow: 0.5px 0.5px 0.5px #838383;
$tabTextShadow: 0.5px 0.5px #000;
$lightTabTextShadow: 0.5px 0.5px #a3a3a3;
// ----- End Text Shadow Styles

// ----- Start Fonts
$primaryFont: 'Arimo', 'Roboto', 'Fira Sans', sans-serif;
$headerFont: 'Anton', 'Lato', 'Roboto', 'Fira Sans', sans-serif;
// ----- End Fonts

// ----- Start Button Styles
$buttonBgPrimary: #65aa86;
$buttonBorderPrimary: #83c4a3;
$buttonBgPrimaryHov: $headerDarkGreen;
$buttonBorderPrimaryHov: $headerDarkGreen;
// ----- End Button Styles

$navbarHeight: 4rem;
$leftSidebarWidth: 28rem;
$sidebarHeights: calc(100vh - 4.29rem);
$sidebarMarginTop: 1px;

// global header styling
.section-header,
.chart-header {
    color: $primary-mustard;
    font-size: 1.3rem;
    font-weight: 600;
    text-shadow: $linkTextShadow;
}