@use '../../main';

.dropdown-wrapper {
    margin: 0;

    .dropdown-label {
        text-align: left;
        font-weight: bold;
        padding-left: 0.2rem;
    }

    .ui.selection.dropdown {
        // can add styling from page where it's invoked also
        border: 1px solid main.$primary-light-blue;

        .default.text {
            // can add styling from page where it's invoked also
            color: main.$headerBlue !important;
            font-weight: 600;
            margin-left: 5px;
        }

        .text {
            color: main.$headerBlue !important;
            margin-left: 5px;
        }

        .divider.text {
            font-weight: 600;
        }

        .menu {
            margin-top: 1px;
            border: 0.5px solid lightgray;
            /* Chrome / Safari */
            -webkit-user-select: none;
            /* Firefox */
            -moz-user-select: none;
            /* Internet Explorer 10+  */
            -ms-user-select: none;
            user-select: none;
        }

        i.dropdown.icon {
            // can add styling from page where it's invoked also
        }
    }
}