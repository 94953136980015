@use '../../../main';

.data-tabs {
    margin: 1.25rem 1rem;
    width: calc(100% - 3rem);

    .select-metric-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: main.$headerBlue;
        margin-top: -1.75rem;

        .dropdowns-metric-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .select-metric {
                padding: 0 5px;

                .dropdown-wrapper {
                    margin: 0 0.5rem;
                    width: 275px;
                }
            }
        }

        .selected-metric {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 700;
            margin-top: 20px;

            .icon {
                height: fit-content;
                margin: 2px 5px 0;
            }
        }
    }

    .menu-tab-view-wrapper {
        margin-top: 20px;

        .ui.menu.tabular {
            border-bottom: none;
            flex-wrap: wrap;

            .item {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                min-width: 11.6rem;
                font-weight: bold;
                color: #9c9c9c;
                border: none;
                border-bottom: 5px solid transparent;
                border-radius: 10px 10px 0 0;
                margin-right: 5px;
            }

            .active,
            .item:hover {
                color: white;
                text-shadow: 0.5px 0.5px white;
                text-shadow: 0.5px 0.5px black;
                font-weight: bolder;
                margin-bottom: 0;
                outline: 1px solid #729fb9;
                border-bottom: 5px solid #729fb9;
                background: #cbe6f5;
                background: linear-gradient(0deg, #9ac5de 0%, #458eb7 100%);
                border-radius: 10px 10px 0 0 !important;
            }

            .item:first-child {
                margin-left: 1px;
            }

            .item:last-child {
                min-width: 11.5rem;
                margin-right: 0 !important;
            }
        }

        .ui.bottom.attached.segment.tab-view {
            width: 100%;
            height: calc(100vh - 18.8rem);
            border-top: 1px solid lightgray !important;
            border-radius: 0 0 0.5rem 0.5rem;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 !important;
            padding-bottom: 0 !important;
            margin: 0 !important;

            .sectionB,
            .sectionC {
                padding: 1rem;
            }
        }

        .ui.bottom.attached.segment.tab-view::-webkit-scrollbar {
            appearance: none;
            width: 0;
        }

        .ui.bottom.attached.segment.tab-view::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: #cfcfcf7c;
        }

        .ui.bottom.attached.segment.tab-view::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #e8e8e8;
        }
    }
}

@media only screen and (max-width: 1120px) {
    .data-tabs {
        .menu-tab-view-wrapper {
            .ui.menu.tabular {
                .item {
                    min-width: 8rem;
                }

                .item:last-child {
                    min-width: 8rem;
                    margin-right: 0 !important;
                }
            }
        }
    }
}