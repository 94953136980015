@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&family=Lato:wght@300;400;700;900&family=Anton:wght@400&display=swap');

.App {
    text-align: center;
    height: calc(100vh);
    font-family: "Arimo", "Lato", "Segoe UI", sans-serif;
    overflow: hidden;
}

// hides scrollbar
::-webkit-scrollbar {
    appearance: none;
    width: 0;
}

.hide {
    display: none;
}

@media only screen and (max-width: 321px) {
    .App {
        display: none;
    }

    .hide {
        display: block;
        padding: 30px;
        margin: 20px;
        text-align: center;
        font-weight: bolder;
        border: 1px solid lightgray;
        border-radius: 10px;

        img {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}