@use '../../../main';

.sectionA {
    padding: 2rem 0;

    .sectionA-button-group-wrapper {
        .sectionA-button-group {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    .ui.divider {
        z-index: 100000;
        margin: 10px 0;
    }

    .sectionA-charts-wrapper {
        margin: 1px 10px;

        .charts-table-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 2rem 1rem 0 0;

            .outer-chart-wrapper-w-table {
                display: flex;
                flex-direction: column;

            }

            .outer-chart-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
            }

            .outer-chart-wrapper,
            .outer-chart-wrapper-w-table {
                .chart-wrapper {
                    .chart-header {
                        text-align: center;
                        color: main.$primary-mustard;
                        font-size: 1.3rem;
                        font-weight: 600;
                        text-shadow: main.$linkTextShadow;
                        padding-left: 5px;
                        margin: 30px 0 0;

                        span {
                            font-size: 1.1rem;
                            color: #656565;
                            text-shadow: none;
                        }
                    }

                    .chart-alternative {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                        padding: 0;
                        max-height: 430px !important;
                        overflow: hidden;
                        margin-top: -3.5rem;

                        .js-plotly-plot {
                            padding: 0 !important;

                            .plotly {
                                .modebar {
                                    transform: translateX(-75%) !important;
                                    padding-top: 67px;
                                }
                            }
                        }

                        .missing-chart-text-wrapper {
                            display: flex;
                            flex-direction: column;
                            padding: 3.5rem 2rem 1rem;
                            margin: 0 1rem;
                            width: 500px;
                            min-height: 10.5rem;
                            border-radius: 5px;
                            border: 1px solid lightgray;

                            .missing-chart-text {
                                width: 100%;
                                text-align: left;
                                padding: 0.4rem 0;
                            }
                        }
                    }

                    .chart {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                        padding: 0;
                        max-height: 430px !important;
                        overflow: hidden;
                        margin-top: -3.5rem;
                        min-width: 600px;

                        .js-plotly-plot {
                            padding: 0 !important;


                            .plotly {
                                .modebar {
                                    transform: translateX(-105%);
                                    padding-top: 67px;
                                }
                            }
                        }

                        .missing-chart-text-wrapper {
                            display: flex;
                            flex-direction: column;
                            padding: 3.5rem 2rem 1rem;
                            margin: 0 1rem;
                            width: 500px;
                            min-height: 10.5rem;
                            border-radius: 5px;
                            border: 1px solid lightgray;

                            .missing-chart-text {
                                width: 100%;
                                text-align: left;
                                padding: 0.4rem 0;
                            }
                        }
                    }
                }
            }

            .sectionA-table {
                flex: 1;
                margin: 2rem 1rem 0 2rem;
                border-radius: 5px !important;
                border: 1px solid lightgray !important;
                overflow: hidden;
                height: fit-content;
                min-width: 25%;

                .ui.structured.table {
                    border-radius: 5px !important;
                    border: 0 !important;

                    thead {
                        tr {
                            th {
                                font-size: 0.9rem;
                                padding: 0.8rem 0.5rem;
                                text-align: center;
                                line-height: 0.9rem;
                                color: white;
                                text-shadow: main.$tabTextShadow;
                                background-color: main.$primary-navbar;

                                .median {
                                    font-size: 0.8rem;
                                    font-weight: 400;
                                    text-align: center;
                                    margin-top: 0.2rem;
                                    text-shadow: none;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                font-size: 0.9rem;
                                padding: 0.3rem 0.5rem;
                            }

                            td:last-child {
                                padding-left: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}