@use '../../main';

.button-component {
    .ui.button {
        background-color: main.$buttonBgPrimary;
        background-color: rgb(99, 150, 124);
        background: linear-gradient(180deg, rgba(99, 150, 124, 1) 0%, rgba(101, 170, 134, 1) 100%);
        border: 1.5px solid main.$buttonBorderPrimary;
        color: white;
        box-shadow: main.$btnBoxShadow;
        transition: all 0s linear; // prevents white flash on hover

        .label {
            display: inline;
        }

        .overlay {
            display: none;
            text-transform: uppercase;
        }
    }

    .ui.button {

        //  targets all states of the button
        &:hover,
        &:focus,
        &:active {
            color: white;
        }
    }

    .ui.button:hover {
        background-color: main.$buttonBgPrimaryHov;
        border: 1.5px solid main.$buttonBorderPrimaryHov;
        box-shadow: none;

        .label {
            display: none;
        }

        .overlay {
            display: inline;
        }
    }
}