@use '../../../main';

.sectionB {
    padding: 0;
    margin: 0 0 0.5rem;

    .flow-criteria {
        padding-top: 0.5rem;

        .section-header {
            padding: 0;
            margin: 0 0 0.5rem;
            // see main.scss for global styling
        }

        .flow-criteria-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 2rem 1rem 0.5rem;

            .flow-criteria-image {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex: 1;
                padding: 0.2rem 2rem 0.2rem 0.5rem;
                margin: 0;

                img {
                    width: 100%;
                }
            }

            .flow-criteria-text {
                flex: 1;
                text-align: left;
                padding: 0 0 0 2rem;
            }
        }
    }

    .select-eco-wrapper {
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;

        .select-eco {
            padding: 0.313rem 0.625rem 0.625rem;

            .section-header {
                margin: 1rem 0 1.5rem;
            }

            .dropdown-wrapper {
                width: 30rem;
            }
        }
    }

    .section-b-chart-wrapper {
        min-height: 300px;
        margin: 0.625rem 0 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .chart-wrapper {
            min-height: 50px;
            margin-top: 20px;

            .chart-header {
                // see main.scss for global styling
                margin-top: 20px;
            }
        }

        .chart {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-top: -2rem;
            min-width: 600px;

            .missing-chart-text-wrapper {
                display: flex;
                flex-direction: column;
                padding: 3.5rem 2rem 1rem;
                margin: 0 1rem;
                width: 500px;
                min-height: 10.5rem;
                border-radius: 5px;
                border: 1px solid lightgray;

                .missing-chart-text {
                    width: 100%;
                    text-align: left;
                    padding: 0.4rem 0;
                }
            }

            .js-plotly-plot {
                padding: 0 !important;

                .plotly {
                    .modebar {
                        transform: translateX(-75%);
                        padding-top: 67px;
                    }
                }
            }
        }
    }
}