@use '../../../main';

.sectionC {
    .sectionC-button-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 1rem 0 2rem;
    }

    .select-eco-wrapper {
        display: flex;
        display: flex;
        flex-direction: column;
        align-items: center;

        .select-eco {
            padding: 0.5rem 0.624rem;
            margin-bottom: 1rem;

            .dropdown-wrapper {
                width: 30rem;

                .ui.selection.dropdown {
                    border: 1px solid main.$primary-light-blue;

                    .default.text {
                        color: main.$headerBlue !important;
                        margin-left: 5px;
                    }


                    .text {
                        color: main.$headerBlue !important;
                        margin-left: 5px;
                    }

                    .divider.text {
                        font-weight: 600;
                    }

                    .menu {
                        margin-top: 1px;
                        border: 0.5px solid lightgray;
                        /* Chrome / Safari */
                        -webkit-user-select: none;
                        /* Firefox */
                        -moz-user-select: none;
                        /* Internet Explorer 10+  */
                        -ms-user-select: none;
                        user-select: none;

                        .item {
                            padding: 10px !important;
                            margin: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .sectionC-chart-wrapper {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;

        .chart-wrapper {
            min-height: 50px;
            margin: 10px 0;

            .chart-header {
                margin-top: 20px;
                // see main.scss for global styling
            }

            .chart {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 0;
                margin-top: -38px;
                min-width: 600px;

                .missing-chart-text-wrapper {
                    display: flex;
                    flex-direction: column;
                    padding: 3.5rem 2rem 1rem;
                    margin: 0 1rem;
                    width: 500px;
                    min-height: 10.5rem;
                    border-radius: 5px;
                    border: 1px solid lightgray;

                    .missing-chart-text {
                        width: 100%;
                        text-align: left;
                        padding: 0.4rem 0;
                    }
                }

                .js-plotly-plot {
                    padding: 0 !important;

                    .plotly {
                        .modebar {
                            transform: translateX(-75%);
                            padding-top: 67px;
                        }
                    }
                }
            }
        }
    }
}