@use "../../main";

.main-wrapper {
    padding: 0;
    margin: 0;

    .layout {
        padding: 0;
        margin: 0;
    }
}